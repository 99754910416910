import React from "react";
import Layout from "../components/layout/Layout";
import { timetables } from "../data/orari/timetables";
import { FeatureTime } from "../components/FeatureTime";
import ContactSection from "../components/home-page/ContactSection";
import Seo from "../components/layout/SEO";

const Orari = () => {
  return (
    <Layout>
      <Seo
        title={"Orari Lezioni e Visite Mediche | Autoscuola Faro, Pisa"}
        description={"Scopri gli orari aggiornati dell'autoscuola per le lezioni teoriche dei corsi e l'orario della visita medica per la patente."}
        keywords={[
          "orari corsi autoscuola Faro Pisa",
          "orari visite mediche autoscuola Faro Pisa"
        ]}
      />

      <FeatureTime orari={timetables} />
      {/* <section id="#orario-corsi-teorici">
        <div className="container mx-auto flex px-5 py-6 items-center justify-center flex-col">

          <div className="text-center mb-12">
            <h1 className="sm:text-6xl text-4xl font-medium text-center title-font text-blue-900 mb-4">
              {orari.title}
            </h1>
            <p className="text-2xl text-blue-900 leading-relaxed">
              {orari.subtitle}
            </p>
          </div>
        </div>

        <div className="mx-auto flex px-4 py-6 items-center justify-center flex-col xl:px-36">
          <h1 className="text-4xl underline text-blue-900 sm:text-left text-center">Orari lezioni teoriche</h1>
          <h1 className="text-xl text-blue-900 my-8 text-center sm:px-48 px-12">{orari.par1}</h1>
          <div className="bg-blue-100 rounded w-3/4 flex py-6 items-center justify-center flex-col p-24">
            {days.map((d) => (
                <div
                  id={d.id}
                  className="border-b border-blue-500 w-full my-4 pb-4 px-12 text-xl text-blue-900 items-center justify-center flex flex-col md:flex-row"
                >
                  <h1 className="text-blue-900 w-1/2 text-center sm:px-4">{d.day}</h1>
                  <p className="text-blue-900 md:w-1/2 w-full text-xl text-center">{d.time1}</p>
                  <p className="text-blue-900 md:w-1/2 w-full text-xl text-center mx-4">{d.time2}</p>
                </div>
            ))}

          </div>
        </div>
      </section>
      <p className="border-blue-500 border-b mx-56 text-center"></p>
      <section id="#orario-visite-mediche">
        <div className="container mx-auto flex px-5 py-6 items-center justify-center flex-col">
          <h1 className="text-4xl text-blue-900 sm:text-left text-center">Orari visite mediche</h1>
          <h1 className="text-xl text-blue-900 text-center sm:px-48 px-12">
            {orari.par2}
          </h1>

          <div className="w-4/5 my-12 sm:mx-48 text-xl text-blue-900 items-center justify-center flex flex-row">
            <h1 className="rounded text-blue-900 bg-blue-100 text-2xl w-3/5 p-8 text-center mr-2">
              Giovedì dalle 17:00 in poi
            </h1>
          </div>
        </div>
      </section> */}
      <ContactSection />
    </Layout>
  );
};

export default Orari;
