import { faBook, faStethoscope } from '@fortawesome/free-solid-svg-icons';


export const timetables = {
    title: "Orari",
    list: [
        {
            id: "classes",
            title: "Orario lezioni teoriche",
            order: "order-first",
            icon: faBook,
            afternoon: "Pomeriggio",
            timesAfternoon: [
                {
                    id: "a1",
                    title: "Lunedì",
                    time: "19:00 - 20:00"
                },
                {
                    id: "a2",
                    title: "Mercoledì",
                    time: "19:00 - 20:00"
                },
                {
                    id: "a3",
                    title: "Venerdì",
                    time: "19:00 - 20:00"
                },
            ],
            morning: "Mattina",
            timesMorning: [
                {
                    id: "m1",
                    title: "Lunedì",
                    time: "11:00 - 12:00"
                },
                {
                    id: "m2",
                    title: "Mercoledì",
                    time: "11:00 - 12:00"
                },
                {
                    id: "m3",
                    title: "Giovedì",
                    time: "11:00 - 12:00"
                },
            ],
        },
        {
            id: "medic",
            title: "Orario visite mediche",
            orderFirst: "order-first",
            icon: faStethoscope,
            afternoon: "Pomeriggio",
            timesAfternoon: [
                {
                    id: "a1",
                    title: "Mercoledì",
                    time: "dalle 17 in poi"
                },

            ],
            morning: "",
            timesMorning: null,
        },

    ]
}