import React from "react";
import Orari from '../pages/orari';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const FeatureTime = (props) => {



	return (
		<div className="px-16 md:px-16 lg:px-16 lg:py-20 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">

			<div className="flex flex-col justify-center">
				{props.orari.list.map(t => (
					<div className="w-full flex flex-col sm:flex-row sm:justify-center justify-start my-4">
						<div className="sm:mr-4">
							<div className="sm:mx-0 mx-auto flex items-center justify-center w-24 h-24 mb-4 rounded-full bg-blue-accent-700">
								<FontAwesomeIcon icon={t.icon} className="text-white w-10 h-10" />
							</div>
						</div>
						<div className="sm:mb-8 sm:ml-8 ml-2">
							<h6 className="text-blue-accent-700 sm:text-5xl text-3xl font-roboto font-semibold leading-7 sm:text-start text-center">{t.title}</h6>
							<div className="flex justify-center sm:justify-start">

								{/* Morning section */}
								{t.morning === "" & t.timesMorning === null ? null :
									<div className="flex flex-col mr-16 mt-8">
										<p className="mb-3 sm:text-4xl text-3xl text-gray-900 border-b border-lynch">
											{t.morning}
										</p>
										<ul className="mb-4 space-y-2">
											{t.timesMorning.map(m => (
												<li className="flex flex-col items-start">

													<p className="text-xl text-gray-900">{m.title}</p>
													<p className="text-xl text-gray-900">{m.time}</p>

												</li>
											))}
										</ul>
									</div>
								}

								{/* Afternoon section */}
								<div className={"flex flex-col mt-8"}>
									<p className="mb-3 sm:text-4xl text-3xl text-gray-900 border-b border-lynch">
										{t.afternoon}
									</p>
									<ul className="mb-4 space-y-2">
										{t.timesAfternoon.map(a => (
											<li className="flex flex-col items-start">

												<p className="text-xl text-gray-900">{a.title}</p>
												<p className="text-xl text-gray-900">{a.time}</p>

											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};